import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_colp_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import Services from "../components/Services";
import image1 from "../assets/images/icons/Exclusive Offers and Deals.png";
import image2 from "../assets/images/icons/Expert Guidance.png";
import image3 from "../assets/images/icons/Reliable and Trusted.png";
import image4 from "../assets/images/icons/Seamless Connection to Top Providers.png";
import animationData from "../components/4.json"
export default function GgColp1() {
  useLoadScript("../Scripts/Home_colp_lps.js");
  const HeroData = {
    Heading: `Transform Your TV Experience`,
    Text: `Explore the Best Satellite TV Packages Today`
  }
  const serviceData = [
    {
      title: "Seamless Connection to Top Providers",
      description: "Connecting you with leading providers, offering you a curated selection of top-tier options. No more endless searches – we bring the best to you.",
      icon: image4 // Placeholder path, replace with actual
    },
    {
      title: "Expert Guidance",
      description: " We provide you with detailed information, comparisons, and recommendations",
      icon: image2
    },
    {
      title: "Exclusive Offers and Deals",
      description: "Maximize your savings. Gain access to special promotions and discounts that you won't find elsewhere.",
      icon: image1
    },
    {
      title: "Reliable and Trusted",
      description: " We partner with reputable providers known for their reliability, quality, and excellent customer service so you get the best in the industry.",
      icon: image3
    }
  ];
  const ContactData = {
    Heading: `Your Perfect TV Package is Just a Call Away
Reach Out and Enhance Your Viewing Experience`
  }
  const PhoneNumber = {
    PhoneNo: "(866) 628-0097",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection animationData={animationData} HeroData={HeroData} PhoneNumber={PhoneNumber}/>
 
      <Services serviceData={serviceData} PhoneNumber={PhoneNumber} />
      <Contact ContactData={ContactData} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
