import React from "react";
import Image from "../assets/images/photos/wireless-television-antenna-sign-download_1172-230.avif";
export default function Contact({ PhoneNumber, ContactData }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="Contact">
      <div className="promo-message">
        <h1>
          {ContactData.Heading}
        </h1>
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
        </div>
      </div>
      <div className="promo-graphic">
        {/* Placeholder for the paper airplane graphic */}
        <img src={Image} alt="Paper Airplane" />
      </div>
      
    </div>
  );
}
