import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import image1 from "./assets/images/icons/Exclusive Offers and Deals.png";
import image2 from "./assets/images/icons/Expert Guidance.png";
import image3 from "./assets/images/icons/Reliable and Trusted.png";
import image4 from "./assets/images/icons/Seamless Connection to Top Providers.png";
import animationData from "./components/4.json"
import animationData1 from "./components/1.json"
export default function landingPage() {
  const HeroData = {
    Heading: `Unlock Your Entertainment Potential`,
    Text: `Discover the Best Satellite TV Offers Near You`
  }
  const serviceData = [
    {
      title: "Seamless Connection to Top Providers",
      description: "We simplify the process of finding the best satellite TV services by connecting you with leading providers, offering you a curated selection of top-tier options. No more endless searches – we bring the best to you.",
      icon: image4 // Placeholder path, replace with actual
    },
    {
      title: "Expert Guidance",
      description: "Our team of experts is here to guide you every step of the way. We provide you with detailed information, comparisons, and recommendations, ensuring you make an informed decision that suits your entertainment needs.",
      icon: image2
    },
    {
      title: "Exclusive Offers and Deals",
      description: "We pride ourselves on bringing you the most competitive deals and exclusive offers available. By choosing us you gain access to special promotions and discounts that you won't find elsewhere, maximizing your savings.",
      icon: image1
    },
    {
      title: "Reliable and Trusted",
      description: "Prime Satellite Deals is a name you can trust. We partner with reputable providers known for their reliability, quality, and excellent customer service. Rest assured that you're getting the best in the industry when you choose us.",
      icon: image3
    }
  ];
  const ContactData = {
    Heading: `Elevate Your Viewing Experience Connect to Premium Satellite Services`
  }
  const PhoneNumber = {
    PhoneNo: "(833) 334-0608",
  };

  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection animationData={animationData} HeroData={HeroData} PhoneNumber={PhoneNumber}/>
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <HowItWorks animationData={animationData1} PhoneNumber={PhoneNumber} />
      <Services serviceData={serviceData} PhoneNumber={PhoneNumber} />
      <Contact ContactData={ContactData} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
