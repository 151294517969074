import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";

export default function PrivacyPolicy() {
  const PhoneNumber = {
    PhoneNo: "(833) 334-0608",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="65"
        class="elementor elementor-65"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-4d2106 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="4d2106"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-583dc70"
              data-id="583dc70"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-767f1370 elementor-widget elementor-widget-heading"
                  data-id="767f1370"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Privacy Policy
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-51fc6a11 elementor-widget elementor-widget-text-editor"
                  data-id="51fc6a11"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Intermedia Marketing Group LLC operates the&nbsp;
                      <a href="https://firstrateinjuryattorneys.com/">
                        <strong>Prime Satelliete Deals</strong>
                      </a>{" "}
                      website, which provides the SERVICE.
                    </p>
                    <p>
                      This page informs website visitors regarding our policies
                      regarding the collection, use, and disclosure of Personal
                      Information if anyone decides to use our Service, on the
                      Prime Satellite Deals website.
                    </p>
                    <p>
                      If you choose to use our Service, then you agree to the
                      collection and use of information in relation to this
                      policy. The Personal Information that we collect is used
                      for providing and improving the Service. We will not use
                      or share your information except as described in this
                      Privacy Policy.
                    </p>
                    <p>
                      The terms used in this Privacy Policy have the same
                      meanings as our Terms and Conditions, which are accessible
                      at&nbsp;
                      <a href="https://primesatellitedeals.com/">
                        <strong>PrimeSatelliteDeals.com</strong>
                      </a>
                      &nbsp;unless otherwise defined in this Privacy Policy.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-3f056464 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="3f056464"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-32fd7820"
              data-id="32fd7820"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-3fb23d6a elementor-widget elementor-widget-heading"
                  data-id="3fb23d6a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Definitions
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-728c89ac elementor-widget elementor-widget-text-editor"
                  data-id="728c89ac"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <h4>Service</h4>
                    <p>
                      Service is the&nbsp;
                      <a href="https://primesatellitedeals.com/">
                        PrimeSatelliteDeals.com
                      </a>
                      &nbsp;operated by Intermedia Marketing Group LLC.
                    </p>
                    <h4>Personal Data</h4>
                    <p>
                      Personal Data pertains to information about a living
                      person(s) who can be identified from this data or other
                      information in our possession or likely to come into our
                      possession.
                    </p>
                    <h4>Usage Data</h4>
                    <p>
                      Usage Data has been collected automatically and is
                      generated by the use of the Service, such as the duration
                      of a visit to the site.
                    </p>
                    <h4>Cookies</h4>
                    <p>
                      Cookies are files with a small amount of data commonly
                      used as a unique anonymous identifier. These are sent to
                      your browser from the website you visit and stored on your
                      computer’s hard drive.
                    </p>
                    <h4>Data Controller</h4>
                    <p>
                      Data Controller pertains to the legal entity though
                      controls your information while participating in the
                      Service. We are a Data Controller of your personal
                      information. We determine the purposes for which it will
                      be processed and how it’s done in an effective manner that
                      ensures compliance with all relevant laws while still
                      maintaining the trust of our users.
                    </p>
                    <h4>Data Processors (or Service Providers)</h4>
                    <p>
                      The Data Processor (or Service Provider) is any natural or
                      legal person who processes data on behalf of the Data
                      Controller. We may use various service providers to
                      process your information more effectively.
                    </p>
                    <h4>Data Subject (or User)</h4>
                    <p>
                      Data Subjects are any individuals who use our Service and
                      whose Personal Data we collect.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-64de65b4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="64de65b4"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7e04ae86"
              data-id="7e04ae86"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7ef11927 elementor-widget elementor-widget-heading"
                  data-id="7ef11927"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Information Collection &amp; Use
                    </h2>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-220b5497 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="220b5497"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f63ab4"
              data-id="9f63ab4"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-fbb75d6 elementor-widget elementor-widget-text-editor"
                  data-id="fbb75d6"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <h4>Personal Data</h4>
                    For a better experience, while using our Service, we may
                    require you to provide us with certain personally
                    identifiable information, including but not limited to your
                    name, phone number, and postal address. The information that
                    we collect will be used to contact or identify you.
                    <span>
                      We will only use your Personal Data to contact you with
                      newsletters, marketing or promotional materials, and other
                      information that may be interesting. You can opt out by
                      following instructions in any email we send — however,
                      this means unsubscribing from all emails which could
                      contain important updates.
                    </span>
                    <h4>Usage Data</h4>
                    We want to inform you that whenever you visit our Service,
                    we collect information that your browser sends to us called
                    Usage Data. This Usage Data may include information such as
                    your computer’s Internet Protocol (“IP”) address, browser
                    version, pages of our Service that you visit, the time and
                    date of your visit, the time spent on those pages, and other
                    statistics.
                    <h4>Location Data</h4>
                    Location data is used to provide features of our service and
                    improve and customize the way in which we offer it. You
                    control when your location services are activated or
                    deactivated by using settings on any device you use for
                    internet browsing with us — including mobile devices.
                    <h4>Cookie Data</h4>
                    Our website uses Cookies to collect information and to
                    improve our Service. You can either accept or refuse these
                    cookies and know when a cookie is being sent to your
                    computer. If you choose to refuse our cookies, you may not
                    be able to use some portions of our Service.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-71a372d0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="71a372d0"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-53d832a1"
              data-id="53d832a1"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-389893af elementor-widget elementor-widget-heading"
                  data-id="389893af"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Examples of Cookies we use include:
                    </h2>{" "}
                  </div>
                </div>
              
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6029ca2f"
                      data-id="6029ca2f"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-background-overlay"></div>
                        <div
                          class="elementor-element elementor-element-2a9d9fe elementor-widget elementor-widget-heading"
                          data-id="2a9d9fe"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              Session Cookies
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-23e3cd1c elementor-widget elementor-widget-text-editor"
                          data-id="23e3cd1c"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>allows for Service operation</p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5dd44a4f"
                      data-id="5dd44a4f"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-background-overlay"></div>
                        <div
                          class="elementor-element elementor-element-b17e268 elementor-widget elementor-widget-heading"
                          data-id="b17e268"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              Preference Cookies
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-1ba15750 elementor-widget elementor-widget-text-editor"
                          data-id="1ba15750"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              allows for remembering your preferences and
                              settings
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-696a1820"
                      data-id="696a1820"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-background-overlay"></div>
                        <div
                          class="elementor-element elementor-element-7411490f elementor-widget elementor-widget-heading"
                          data-id="7411490f"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">
                              Security Cookies{" "}
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-17684a25 elementor-widget elementor-widget-text-editor"
                          data-id="17684a25"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>for security purposes</p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
             
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-6986f6d8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="6986f6d8"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-60dd4935"
              data-id="60dd4935"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7b1f8678 elementor-widget elementor-widget-text-editor"
                  data-id="7b1f8678"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <h4>Service Providers</h4>
                    We may employ third-party companies and individuals due to
                    the following reasons:
                    <ul>
                      <li>To facilitate our Service.</li>
                      <li>To provide the Service on our behalf.</li>
                      <li>To perform Service-related services; or</li>
                      <li>
                        To assist us in analyzing how our Service is used.
                      </li>
                    </ul>
                    We want to inform our Service users that these third parties
                    have access to your Personal Data. The reason is to perform
                    the tasks assigned to them on our behalf. However, they are
                    obligated not to disclose or use the information for any
                    other purpose.
                    <h4>Security</h4>
                    We value your trust in providing us with your Personal Data.
                    Thus, we strive to use commercially acceptable means of
                    protecting it. But remember that no method of transmission
                    over the internet or method of electronic storage is 100%
                    secure and reliable, and we cannot guarantee its absolute
                    security.
                    <h4>Use of Data</h4>
                    Intermedia Marketing Group LLC collects your browsing data
                    so to provide you with various services. The collected
                    information is used for several purposes, including:
                    <ul>
                      <li>
                        To notify users about changes in service offerings or
                        adjustments based on user feedback
                      </li>
                      <li>
                        Maintain an effective customer experience by gathering
                        analysis of how people are using the Service’s features
                        when allowed to participate via email newsletter
                        subscription process (if applicable)
                      </li>
                      <li>
                        Monitoring technical issues affecting performance across
                        their network which may lead to providing news
                        updates/special offers
                      </li>
                    </ul>
                    <h4>
                      Processing Personal Data under the General Data Protection
                      Regulation (GDPR)
                    </h4>
                    If you are from the European Economic Area (EEA), Intermedia
                    Marketing Group LLC may collect and use the Personal Data
                    described in this Privacy Policy depending on the specific
                    context in which it is collected.
                    <span>
                      Intermedia Marketing Group LLC may process your Personal
                      Data due to:
                    </span>
                    <ul>
                      <li>A contract needing to be performed and fulfilled</li>
                      <li>You have given us express permission to do so</li>
                      <li>
                        Processing is in our legitimate interests and does not
                        override your rights
                      </li>
                      <li>Compliance with the law</li>
                    </ul>
                    <h4>Retention of Data</h4>
                    Intermedia Marketing Group LLC will retain your Personal
                    Data for as long as necessary for the sole purposes set
                    forth within this Privacy Policy. We will retain and use
                    your Personal Data only to comply with legal obligations,
                    resolve disputes, and enforce our legal agreements and
                    policies.
                    <span>
                      Intermedia Marketing Group LLC will also retain Usage Data
                      for internal analysis, such as when needed to strengthen
                      the security or improve the functionality of our Service.
                      We will also retain this data as legally obligated to do
                      so.
                    </span>
                    <h4>Transfer of Data</h4>
                    Your information, including your Personal Data, may be
                    transferred to and maintained on computers located outside
                    of your state, province, country, or other governmental
                    jurisdiction where data protection laws may differ from
                    those in your jurisdiction.
                    <span>
                      By providing us with any information you choose to share,
                      including Personal Data like name and address or phone
                      number, you give Intermedia Marketing Group LLC permission
                      to transfer that data across borders within the United
                      States (and other countries), which is processed there.
                    </span>
                    <span>
                      You agree this Privacy Policy followed means to accept all
                      obligations imposed upon its use at destinations outside
                      the United States.
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-16773073 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="16773073"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-679b0955"
              data-id="679b0955"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-367688ef elementor-widget elementor-widget-heading"
                  data-id="367688ef"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Disclosure Of Data
                    </h2>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-c18e866 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="c18e866"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-34f2b230"
              data-id="34f2b230"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-20d53ba elementor-widget elementor-widget-text-editor"
                  data-id="20d53ba"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <h4>Disclosure for Law Enforcement</h4>
                    Intermedia Marketing Group LLC has a responsibility to
                    protect your Personal Data. We will never release any of it
                    without complying with all applicable laws and responding
                    properly if asked by law enforcement or other authorities.
                    <h4>Legal Requirements</h4>
                    Intermedia Marketing Group LLC may disclose your Personal
                    Data in the good faith belief that such action is necessary
                    to:
                    <ul>
                      <li>
                        To notify users about changes in service offerings or
                        adjustments based on user feedback
                      </li>
                      <li>Comply with a legal obligation</li>
                      <li>
                        Protect and defend the rights or property of Intermedia
                        Marketing Group LLC
                      </li>
                      <li>
                        Protect clients &amp; employees from any harm caused by
                        their actions where it cannot be avoided through
                        reasonable precautions taken beforehand
                      </li>
                      <li>
                        Prevent or investigate potential wrongdoing in
                        connection with the Service
                      </li>
                      <li>
                        Protect the personal safety of users of the Service
                      </li>
                      <li>Protect against personal liability</li>
                    </ul>
                    <h4>Security of Data</h4>
                    The security of your data is a top priority for us. We’ll do
                    everything we can to protect it, but remember that no
                    electronic storage media method will ever be 100% secure
                    when sending information over the internet.
                    <h4>
                      Our Policy on “Do Not Track” Signals under the California
                      Online Protection Act
                    </h4>
                    We do not support Do Not Track (DNT). You can set this
                    preference in your web browser to inform websites that they
                    should not track your activity.
                    <span>
                      You can enable or disable this feature through the
                      preferences menu on any given website by adjusting the
                      appropriate settings on your web browser.
                    </span>
                    <h4>Your Data Protection Rights Under GDPR</h4>
                    If you are a resident of the European Economic Area (EEA),
                    then we aim to make certain that your Personal Data is
                    processed fairly and in accordance with data protection
                    laws.
                    <span>
                      Should you request information about the Personal Data we
                      hold about you or want it removed, please contact us so we
                      may act accordingly.
                    </span>
                    <span>
                      You may also have the following data protection rights:
                    </span>
                    <ul>
                      <li>
                        <strong>The right to be informed —&nbsp;</strong>You
                        have the right to be told how your Personal Data will be
                        processed by the Data Controller. This is normally done
                        through a privacy notice.
                      </li>
                      <li>
                        <strong>
                          The right to access your Personal Data —
                        </strong>
                        You have the right to ask the Data Controller whether
                        they are processing your Personal Data, and if they are,
                        you can request a copy of that personal information.
                      </li>
                      <li>
                        <strong>The right of rectification —</strong>You have
                        the right to ensure your information is rectified if it
                        is found to be incomplete or inaccurate.
                      </li>
                      <li>
                        <strong>
                          The right to have your Personal Data erased —
                        </strong>
                        Also known as the “right to be forgotten” and, in
                        certain circumstances, you can ask the Data Controller
                        to erase your data.
                      </li>
                      <li>
                        <strong>The right to withdraw consent —&nbsp;</strong>
                        You have the right to withdraw your consent at any point
                        in time when Intermedia Marketing Group LLC relies on
                        your consent to process personal information.
                      </li>
                      <li>
                        <strong>
                          The right to restrict processing of your Personal Data
                          —
                        </strong>
                        You have the right to ask the Data Controller to
                        restrict or stop processing your Personal Data under
                        certain circumstances.
                      </li>
                      <li>
                        <strong>The right to data portability —</strong>You have
                        the right, in certain circumstances, to ask the Data
                        Controller for a copy of your data to re-use for your
                        own purposes.
                      </li>
                      <li>
                        <strong>The right to object —</strong>You have the
                        right, in certain circumstances, to object to the Data
                        Controller processing your data.
                      </li>
                      <li>
                        <strong>
                          Rights in relation to automated decision making and
                          profiling —
                        </strong>
                        You have the right, in certain circumstances, to ask the
                        Data Controller not to make decisions about you that are
                        based solely on automated processing or profiling
                      </li>
                    </ul>
                    Please note that to remain compliant with law enforcement,
                    we may ask that you verify your identity before responding
                    to or taking action on your request.
                    <span>
                      You also have the right to file a complaint with a Data
                      Protection Authority about our collection and use of your
                      information. If you have questions or want more
                      information, please contact your local European Economic
                      Area (EEA) data protection authority.
                    </span>
                    <h4>Service Providers</h4>
                    We may employ third-party companies (Service Providers) to
                    help us deliver our Service, such as by handling customer
                    support or sending email newsletters about new services.
                    These Service Providers have access to Personal Data but are
                    prohibited from disclosing any information you shared with
                    them unless necessary.
                    <span>
                      Service Providers are only to perform tasks on our behalf
                      and may not use or disclose your Personal Data for any
                      other purpose.
                    </span>
                    <h4>Analytics</h4>
                    We may use third-party Service Providers to complete an
                    analysis of the Service’s use and monitor user activity.
                    <h4>Google Analytics</h4>
                    Google uses the data collected from its Analytics service to
                    tailor a more personalized user experience. The information
                    gathered by Google Analytics doesn’t contain any personally
                    identifiable data, like your name or email address. It
                    simply records how many visitors there were on a specific
                    day and time frame.
                    <span>
                      You can choose to opt out of making your activity on the
                      Service available to Google Analytics by installing the
                      appropriate browser add-on. The Google Analytics opt-out
                      browser add-on prevents sharing information with Google
                      Analytics about your activity.
                    </span>
                    <span>
                      For additional information on Google’s privacy practices,
                      visit the Google Privacy Terms web page
                    </span>
                    <a href="https://policies.google.com/privacy?hl=en">
                      &nbsp;found here.
                    </a>
                    <h4>Behavioral Remarketing</h4>
                    Intermedia Marketing Group LLC uses remarketing services to
                    advertise on other third-party websites you visit after
                    visiting the Service. Cookies are used to inform, optimize,
                    and serve advertisements based on prior visits to the
                    Service.
                    <h4>Google AdWords</h4>
                    Google AdWords is a service provided by Google Inc., which
                    allows Intermedia Marketing Group LLC to advertise the
                    Service through online advertisements. You can opt-out
                    <a href="https://adssettings.google.com/authenticated">
                      &nbsp;of this advertising program&nbsp;
                    </a>
                    to not have data collected and used for personalized content
                    based on visitors’ browsing behavior when using web browsers
                    such as Chrome and Firefox.
                    <h4>Bing Ads Remarketing</h4>
                    Bing Ads Remarketing is provided by Microsoft Inc. You can
                    also choose to opt-out of Bing Ads by following their
                    <a href="https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads">
                      &nbsp;instructions located here.
                    </a>
                    <span>
                      Learn about Microsoft privacy policies by visiting
                      their&nbsp;
                    </span>
                    <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
                      Privacy Policy page.
                    </a>
                    <h4>Links to Other Sites</h4>
                    Our Service may contain links to other sites. If you click
                    on a third-party link, you will be directed to that site.
                    Note that these external sites are not operated by us.
                    Therefore, we strongly advise you to review the Privacy
                    Policy of these websites. We have no control over and assume
                    no responsibility for any third-party sites or services’
                    content, privacy policies, or practices.
                    <h4>Children’s Privacy</h4>
                    Our Services do not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from children under 13. If we discover that a child under 13
                    has provided us with personal information, we immediately
                    delete this from our servers. If you are a parent or
                    guardian and you are aware that your child has provided us
                    with personal information, please contact us so that we will
                    be able to take the necessary actions.
                    <h4>Changes to This Privacy Policy</h4>
                    We may update our Privacy Policy from time to time. Thus, we
                    advise you to review this page periodically for any changes.
                    We will notify you of any changes by posting the new Privacy
                    Policy on this page. These changes are effective immediately
                    after they are posted on this page.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
