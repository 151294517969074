import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Component/landingPage.js";
import ScrollToTop from "./Component/Utility/ScrollToTop.js";
//Pages of Inner pages  LP1
import BngLp1 from "./Component/Home_Inner_Pages_lp1/BngLp1.js";
import DlLp1 from "./Component/Home_Inner_Pages_lp1/DlLp1.js";
import FbLp1 from "./Component/Home_Inner_Pages_lp1/FbLp1.js";
import GgLp1 from "./Component/Home_Inner_Pages_lp1/GgLp1.js";
import RdLp1 from "./Component/Home_Inner_Pages_lp1/RdLp1.js";
import GgColp1 from "./Component/Home_Inner_Pages_lp1/GgColp1.js";

//Website Generals Pages

import PrivacyPolicy from "./Component/WebsiteGeneralPages/PrivacyPolicy.js";
import Disclaimer from "./Component/WebsiteGeneralPages/Disclaimer.js";
import TermsCondition from "./Component/WebsiteGeneralPages/TermsCondition.js";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
    
        {/* Pages of Inner Pages LP1 */}
        <Route path="/bng/lp1" element={<BngLp1 />} />
        <Route path="/dl/lp1" element={<DlLp1 />} />
        <Route path="/fb/lp1" element={<FbLp1 />} />
        <Route path="/gg/lp1" element={<GgLp1 />} />
        <Route path="/rd/lp1" element={<RdLp1 />} />
        <Route path="/gg/colp1" element={<GgColp1 />} />
      
        {/* Website General Pages */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms-and-conditions" element={<TermsCondition />} />
      </Routes>
    </Router>
  );
}

export default App;
