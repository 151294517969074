import React from "react";



export default function Services({PhoneNumber, serviceData}) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="Services">
      <h1>Why Choose Us</h1>
      <h2>
      Your Trusted Source for Premium Satellite TV Services</h2>
      <div className="service-cards">
        {serviceData.map((service, index) => (
          <div key={index} className="card">
            <div className="icon">
              <img src={service.icon} alt={`${service.title} icon`} />
            </div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
      <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
    </div>
  );
}
