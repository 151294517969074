import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import Lottie from 'react-lottie'; // Import Lottie
import Image from "../assets/images/photos/gps-location-satelital-service_18591-51099.avif"
export default function HowItWorks({animationData}) {
  const leftRef = useRef();
  const rightRef = useRef();

  useEffect(() => {
    [leftRef, rightRef].forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);


  // Lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The JSON data is passed as a prop to the component
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice' // Adjust according to your needs
    }
  };
  return (
    <div className="HowWorks">
    

      <div className="left-section lottie-animation1" ref={leftRef}>
        {/* Assuming the image would be passed as a prop or imported */}
        <Lottie options={defaultOptions} />
      </div>
      <div className="right-section" ref={rightRef}>
        <div className="text-container">
          <p>
          We are your ultimate destination for finding exceptional satellite TV services. We specialize in connecting you with the top providers in your area, ensuring you get the best deals and superior entertainment experiences.</p>
          
        </div>
      </div>
    </div>
  );
}
