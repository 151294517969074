import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function TermsCondition() {
  const PhoneNumber = {
    PhoneNo: "(833) 334-0608",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div data-elementor-type="wp-page" data-elementor-id="70" class="elementor elementor-70">
						<section class="elementor-section elementor-top-section elementor-element elementor-element-7f9f083f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7f9f083f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-601a879d" data-id="601a879d" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-4ac45fa4 elementor-widget elementor-widget-heading" data-id="4ac45fa4" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
	<h2 class="elementor-heading-title elementor-size-default">Terms and Conditions</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-4457e5bb elementor-widget elementor-widget-text-editor" data-id="4457e5bb" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<p>These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Prime Satellite Deals (“we,” “us,” or “our”), concerning your access to and use of the <a  href="https://primesatellitedeals.com/"><strong>PrimeSatelliteDeals.com</strong></a>&nbsp;website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected to it (collectively, the “Site”).</p><p>You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, you are expressly prohibited from using the Site and must discontinue use immediately.</p>						</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-33ad84f3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="33ad84f3" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1e3a4fa3" data-id="1e3a4fa3" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-3e040153 elementor-widget elementor-widget-text-editor" data-id="3e040153" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<h4 >Intellectual Property</h4>
<p>The Site may contain (or you may be sent via the Site) links to other websites (“Third-Party Websites”) as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties (“Third-Party Content”).</p>
<p><span >Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us. We are not responsible for any Third-Party Websites accessed through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.</span></p>
<p><span >Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof. If you decide to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms and Conditions no longer govern.</span></p>
<p><span >We strongly advise you to read the Third-Party Website terms and conditions and the privacy policies of any site or services you visit or use.</span></p>
<h4 >Indemnification</h4>
<p>You agree to defend, indemnify, and hold First Rate Self Storage harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:</p>
<p><span >(1) use of the Site</span></p>
<p><span >(2) breach of these Terms and Conditions</span></p>
<p><span >(3) any breach of your representations and warranties outlined in these Terms and Conditions</span></p>
<p><span >(4) your violation of the rights of a third party, including but not limited to intellectual property rights</span></p>
<p><span >(5) any overt harmful act toward any other user of the Site with whom you connected via the Site</span></p>
<h4 >Limitation of Liability</h4>
<p>In no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the site, even if we have been advised of the possibility of such damages.</p>
<p><span >Notwithstanding anything to the contrary contained herein, our liability to you for any cause whatsoever and regardless of the form of the action will at all times be limited to the lesser of the amount paid, if any, by you to us during the ONE (1) month period before any cause of action arising or US$100. State laws may not allow limitations on implied warranties or the exclusion or limitation of certain damages.</span></p><p><span >If certain State laws apply to you, some or all disclaimers found within these terms and conditions or limitations may not apply to you, and you may have additional rights.</span></p>
<h4 >Exclusions</h4>
<p>While the limitations above may not apply to you, some countries do not allow exclusions or limitations of liability for consequential damages, so please check with your jurisdiction before purchasing this insurance.</p>
<h4 >Governing Law</h4>
<p>These Terms and Conditions and your use of the Site are governed by and construed following the laws of the State of Florida applicable to agreements made and to be entirely performed within the State of Florida, without regard to its conflict of law principles.</p>
<h4 >Changes</h4>
<p>Supplemental terms and conditions or documents posted on the Site from time to time are hereby expressly incorporated herein by reference. In our sole discretion, we reserve the right to make changes or modifications to these Terms and Conditions at any time and for any reason.</p><p><span >We will alert you about any changes by updating the “Last Updated” date of these Terms and Conditions, and you waive any right to receive specific notice of each such change.</span></p><p><span >You must periodically review these Terms and Conditions to stay informed of updates. You will be subject to and will be deemed to have been made aware of and accepted the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms and Conditions are posted.</span></p>						</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				</div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
