import React, { useEffect, useRef, useState } from "react";
import Lottie from 'react-lottie'; // Import Lottie

export default function HeroSection({ PhoneNumber, HeroData, animationData }) {
  const leftRef = useRef();
  const rightRef = useRef();
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0); // Control the character index directly with state
  const headingText = HeroData.Heading; // "Unlock Your Entertainment Potential"

  useEffect(() => {
    const timer = setInterval(() => {
      if (index < headingText.length) {
        setText((text) => text + headingText.charAt(index)); // Append character at current index
        setIndex(index + 1); // Move to the next character
      } else {
        clearInterval(timer); // Stop when all characters have been appended
      }
    }, 150); // Adjust typing speed here

    return () => clearInterval(timer); // Cleanup on unmount

  }, [index, headingText]); // Reacting to changes in index or headingText

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  // Lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // The JSON data is passed as a prop to the component
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice' // Adjust according to your needs
    }
  };

  return (
    <div className="hero">
      <div className="left-side" ref={leftRef}>
        <h1>{text}</h1>
        <h2>{HeroData.Text}</h2>
      </div>
      <div className="right-side lottie-animation" ref={rightRef}>
        <Lottie options={defaultOptions} />
      </div>
      <div className="btn-group">
        <a href={`tel:${telPhoneNumber}`}>CALL NOW: {PhoneNumber.PhoneNo}</a>
      </div>
    </div>
  );
}
